<template>
  <div class="content-wrapper">
    <div class="backup main-content">
      <div class="page-header">
        <h1>Backup Data</h1>
      </div>
      <div class="message">
        You will be able to backup all of the data here.
      </div>
      <div class="action">
        <button class="btn btn-primary" @click="startBackup">Start Backup</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import queryString from 'query-string';

export default {
  name: 'Backup',
  methods:{
    startBackup(){
      const loginInfo = Common.getLoginInfo();
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${this.apiUrl}/backup?${query}`;
      location.href = apiUrl;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.action{
  margin-top: 50px;
}
</style>
